import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import { IconChevronLeft } from '../components/Icons'
import { uniformUrl } from '../helpers/StringHelpers'
import NewArrivalSlider from '../components/sliders/NewArrivalSlider'
import Seo from '../components/Seo'
import { SitemapLink as Link } from '../components/sitemap/SitemapLink'
import { convertSitemapUrl } from '../utils/sitemapPathnameCleanup'
import useInfiniteScroll from '../hooks/useInfiniteScroll'

const SitemapProductList = ({ products }) => {
  return ( <div className={'flex flex-col gap-2 my-10'}>
    <div className={'flex flex-col'}>

      <div
            className={
              'grid sm:grid-cols-2 lg:grid-cols-4 gap-5 pt-10 mt-7 border-t-2 border-red-700'
            }
      >
        {products?.map(product => {
          return (
            <Link key={product.node.name} className="py-2 text-left" to={product.node.url_key}>
              {product.node.name} ({product.node.sku})
            </Link>
          )
        })}
      </div>
    </div>
    {/* <div className="cms"> */}
    {/*   <h2>{data.category.name}</h2> */}
    {/*   <div dangerouslySetInnerHTML={{ __html: data.category.descriptionTop }} /> */}
    {/*   <div dangerouslySetInnerHTML={{ __html: data.category.description }} /> */}
    {/*   <div dangerouslySetInnerHTML={{ __html: data.category.descriptionBottom }} /> */}
    {/* </div> */}
    {/* <hr /> */}
  </div>
  )
}
SitemapProductList.propTypes = {
  products: PropTypes.array,
}

const SitemapCategoryPage = ({ pageContext, data, location }) => {
  const firstFold = 24
  const pageSize = 500
  const [limit, setLimit] = React.useState(pageSize)

  const loadMore = React.useCallback(() => {
    setLimit(limit => limit + pageSize)
  }, [])
  const nodeRef = useInfiniteScroll(limit, loadMore)
  const ProductsList = data?.products?.edges

  const H1 = data?.category?.headerH1 || pageContext?.categoryName
  const seoDescription = [
    `Site Map | ${H1} With our selection of used jewelry and watches, find the perfect new addition for your style story.`,
    `Site Map | ${H1} Find the perfect new addition for your style story.`,
    `Site Map | ${H1} | Gray and Sons`,
    `Site Map | ${H1}`,
  ]
    .filter(s => s.length <= 160)
    .pop()
  return (
    <Layout
      className={'mx-auto w-10/12'}
      breadcrumbs={false}
    >
      <Seo
        title={`Site Map | ${H1} | Gray and Sons`}
        noindex={false}
        description={seoDescription}
        canonical={convertSitemapUrl(location.pathname)}
      />
      <div className="flex flex-row justify-between align-center">
        <h1 className="z-20 mb-8 ">
          <p className="text-sm text-gray-600">Sitemap</p>
          <Link
                to={uniformUrl(data.category.urlPath)}
                className="text-5xl font-black text-gray sm:text-3xl md:text-4xl xl:text-5xl"
          >
            {H1}
          </Link>
        </h1>
        <Link className="py-2 text-left text-2xl" to="/sitemap">
          <IconChevronLeft className={'text-red-800'} /> Back to the Sitemap Page
        </Link>
      </div>
      {ProductsList.length === 0 ? (
        <div className="py-2 text-left w-full">No products found in this category</div>
      ) : 
        <SitemapProductList products={ProductsList.slice(0, firstFold)} />
      }
      <NewArrivalSlider />
      {limit > firstFold && ProductsList.length > firstFold && (
        <SitemapProductList products={ProductsList.slice(firstFold, limit - firstFold)} />
      )}
      {limit < ProductsList.length && (
        <button
            ref={nodeRef}
            className={'btn-cta p-4 mx-auto grid col-span-1 md:col-span-2 lg:col-span-3 '}
            onClick={loadMore}
        >
          Load More Products
        </button>
      )}
    </Layout>
  )
}

export const query = graphql`
  query SitemapPageQuery($categoryId: Int!) {
    products: allStrapiProduct(
      filter: { categories: { elemMatch: { strapi_id: { eq: $categoryId } } } }
      sort: { fields: [in_stock, stock_date], order: [DESC, DESC] }
    ) {
      edges {
        node {
          sku
          url_key: urlPath
          name
          categories {
            id
            name
          }
        }
      }
    }
    category: strapiCategory(strapi_id: { eq: $categoryId }) {
      name
      headerH1
      description
      descriptionBottom
      descriptionTop
      urlPath
    }
  }
`

SitemapCategoryPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    pageId: PropTypes.string,
    categoryName: PropTypes.string,
    listPages: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        path: PropTypes.string,
        name: PropTypes.string,
        blog: PropTypes.bool,
        press_release: PropTypes.bool,
      })
    ),
  }),
  data: PropTypes.object,
}

SitemapCategoryPage.defaultProps = {
  pageContext: null,
  data: null,
}

export default SitemapCategoryPage
